 // Variable.scss

.nestable-cart {
    overflow: hidden;
}

.dd-handle {
    color: $white;
    background: var(--primary);
    border-radius: 0.3125rem;
	padding: 0.5rem 1rem;
	height:auto;
    border: 1px solid var(--border);
    cursor: all-scroll;
}
.dd-handle:hover {
    color: $white;
    background: var(--primary);
}

.dd3-content:hover {
    color: $white;
    background: var(--primary);
}
.dd3-content {
    color: $white;
}
.dd-item>button{
	line-height:28px;
	color:$white;
    float: #{$dlab-pos-left};
}