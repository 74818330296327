.grid-col {
    padding: 0.5rem !important;
    background:var(--body-bg);
 
}

.row.grid {
    margin-bottom: 1.5rem;
    text-align: center;
}

.row.grid .grid-col:first-child{
    text-align: #{$dlab-pos-left};
}




