
button{
    cursor: pointer;

    &:focus{
        outline: 0;
        box-shadow: none;
    }
}

.btn {
	padding: 0.59375rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    &:hover, &:focus, &:active, &.active{
        outline: 0!important;
    }
	
	@include respond('laptop') {
		padding: 0.625rem 1rem;
		font-size: 0.813rem;
	}
	&.btn-success,&.btn-secondary,&.btn-warning,&.btn-primary,&.btn-danger,&.btn-info{
		color:$white;
	}
	
    &-transparent {
        background-color: transparent;
    }
	&.btn-primary,
	&.btn-secondary,
	&.btn-danger,
	&.btn-info,
	&.btn-dark,
	&.btn-success,
	&.btn-warning{
		&:active{
			color:$white!important;
		}
	}



}
.btn-primary{
	border-color:var(--primary);	
	background-color:var(--primary);
	&:active,
	&:focus,
	&:hover{
		border-color:var(--primary-hover);	
		background-color:var(--primary-hover);	
	}
	&:focus{
		box-shadow:0 0 0 0.25rem var(--rgba-primary-5);
	}
	&:disabled,
	&.disabled{
		background-color: var(--primary);
		border-color: var(--primary);
	}
}

.btn-link{
	color:var(--primary);
	text-decoration: none;
	&:hover{
		color:var(--primary-hover);	
	}
}
.btn-outline-primary {
	color:var(--primary);	
	border-color:var(--primary);
	&:hover{
		border-color:var(--primary-hover);	
		background-color:var(--primary-hover);	
	}
}
.sharp {
	min-width: 2.4rem;
    padding: 0.4375rem;
    height: 2.4rem;
    line-height: 1.5;
    min-height: 2.5rem;
}

.sharp.btn-xs{
    padding: 0.1875rem;
    width: 1.625rem;
    height: 1.625rem;
    min-width: 1.625rem;
    min-height: 1.625rem;
}
.sharp-lg{
	height:2.813rem;
	width:2.813rem;
	line-height: 1.813rem;
	@include respond('laptop') {
		height:2.285rem;
		width:2.285rem;
		line-height:1.4rem;
		font-size: 0.813rem;
	}
}

.btn-block {
    display: block;
    width: 100%;
}

.light {

	&.tp-btn {
		background-color: transparent;
	}

	&.btn-default {
		background: #ADB6C7;
	}

	&.btn-success {
		background: rgba($success, 0.2);
		border-color: rgba($success, 0.2);
		color: $success;

		g [fill] {
			fill: $success;
		}

		&:hover {
			background-color: $success;
			border-color: $success;
			color: #fff;

			g [fill] {
				fill: $white;
			}
		}
	}

	&.btn-info {
		background: rgba($info, 0.2);
		border-color: rgba($info, 0.2);
		color: $info;

		g [fill] {
			fill: $info;
		}

		&:hover {
			background-color: $info;
			border-color: $info;
			color: #fff;

			g [fill] {
				fill: $white;
			}
		}
	}
	&.btn-light {
		background: rgba($light, 0.2);
		border-color: rgba($light, 0.2);
		color: $dark;

		g [fill] {
			fill: $light;
		}

		&:hover {
			background-color: $light;
			border-color: $light;
			

			
		}
	}

	&.btn-primary {
		background-color: var(--rgba-primary-1);
		border-color: var(--rgba-primary-1);
		color: var(--primary);

		g [fill] {
			fill: var(--primary);
		}

		&:focus {
			color: var(--primary);
		}

		&:hover {
			background-color: var(--primary);
			border-color: var(--primary);
			color: #fff;

			g [fill] {
				fill: $white;
			}
		}
	}

	&.btn-secondary {
		background: rgba($secondary, 0.2);
		border-color: rgba($secondary, 0.2);
		color: $secondary;

		g [fill] {
			fill: $secondary;
		}

		&:hover {
			background-color: $secondary;
			border-color: $secondary;
			color: #fff;

			g [fill] {
				fill: $white;
			}
		}
	}

	&.btn-warning {
		background: rgba($warning, 0.2);
		border-color: rgba($warning, 0.2);
		color: $warning;

		g [fill] {
			fill: $warning;
		}

		&:hover {
			background-color: $warning;
			border-color: $warning;
			color: #fff;

			g [fill] {
				fill: $white;
			}
		}
	}

	&.btn-danger {
		background: rgba($danger, 0.2);
		border-color: rgba($danger, 0.2);
		color: $danger;

		g [fill] {
			fill: $danger;
		}

		&:hover {
			background-color: $danger;
			border-color: $danger;
			color: #fff;

			g [fill] {
				fill: $white;
			}
		}
	}

	&.btn-dark {
		background: rgba($dark, 0.5);
		border-color: rgba($dark, 0.5);
		color: $white;

		g [fill] {
			fill: $dark;
		}

		&:hover {
			background-color: $dark;
			border-color: $dark;
			color: #fff;

			g [fill] {
				fill: $white;
			}
		}
	}
}
.btn.tp-btn{
	background-color:transparent;
	border-color: transparent;
	&.btn-default{
		background: #ADB6C7;
	}
	&.btn-success {
		color:$success;
		g [fill]{
			fill:$success;
		}
		&:hover{
			background-color: $success;
			border-color: $success;
			color: #fff;
			g [fill]{
				fill:$white;
			}
		}
	}
	&.btn-info {
		color:$info;
		g [fill]{
			fill:$info;
		}
		&:hover{
			background-color: $info;
			border-color: $info;
			color: #fff;
			g [fill]{
				fill:$white;
			}
		}
	}
	&.btn-primary {
		color:var(--primary);
		background-color:var(--rgba-primary-1);
		g [fill]{
			fill:var(--primary);
		}
		&:hover{
			background-color: var(--primary);
			border-color: var(--primary);
			color: #fff;
			g [fill]{
				fill:$white;
			}
			svg{
				path{
					fill:$white;
				}
			}
		}
	}
	&.btn-secondary {
		color:var(--secondary);
		g [fill]{
			fill:var(--secondary);
		}
		&:hover{
			background-color: $secondary;
			border-color: $secondary;
			color: #fff;
			g [fill]{
				fill:$white;
			}
		}
	}
	&.btn-warning {
		color:$warning;
		g [fill]{
			fill:$warning;
		}
		&:hover{
			background-color: $warning;
			border-color: $warning;
			color: #fff;
			g [fill]{
				fill:$white;
			}
		}
	}
	&.btn-danger {
		color:$danger;
		g [fill]{
			fill:$danger;
		}
		&:hover{
			background-color: $danger;
			border-color: $danger;
			color: #fff;
			g [fill]{
				fill:$white;
			}
		}
	}
	&.btn-light {
		color:$dark;
		g [fill]{
			fill:$dark;
		}
		&:hover{
			background-color: $light;
			border-color: $light;
			color: $dark;
			g [fill]{
				fill:$white;
			}
		}
	}
	&.btn-dark {
		color:$dark;
		g [fill]{
			fill:$dark;
		}
		&:hover{
			background-color: $dark;
			border-color: $dark;
			color: #fff;
			g [fill]{
				fill:$white;
			}
		}
	}
}
.btn.tp-btn-light{
	background-color:transparent;
	border-color: transparent;
	
	&.btn-success {
		color:$success;
		g [fill]{
			fill:$success;
		}
		&:hover{
			background-color: $success-light;
			border-color: $success-light;
			color:$success;
			g [fill]{
				fill:$success;
			}
		}
	}
	&.btn-info {
		color:$info;
		g [fill]{
			fill:$info;
		}
		&:hover{
			background-color: $info-light;
			border-color: $info-light;
			color:$info;
			g [fill]{
				fill:$info;
			}
		}
	}
	&.btn-primary {
		color:var(--primary);
		g [fill]{
			fill:var(--primary);
		}
		&:hover{
			background-color: var(--rgba-primary-1);
			border-color: var(--rgba-primary-1);
			color:var(--primary);
			g [fill]{
				fill:var(--primary);
			}
		}
	}
	&.btn-secondary {
		color:var(--secondary);
		g [fill]{
			fill:var(--secondary);
		}
		&:hover{
			background-color: var(--rgba-secondary-1);
			border-color: var(--rgba-secondary-1);
			color:var(--secondary);
			g [fill]{
				fill:var(--secondary);
			}
		}
	}
	&.btn-warning {
		color:$warning;
		g [fill]{
			fill:$warning;
		}
		&:hover{
			background-color: $warning-light;
			border-color: $warning-light;
			color:$warning;
			g [fill]{
				fill:$warning;
			}
		}
	}
	&.btn-danger {
		color:$danger;
		g [fill]{
			fill:$danger;
		}
		&:hover{
			background-color: $danger-light;
			border-color: $danger-light;
			color:$danger;
			g [fill]{
				fill:$white;
			}
		}
	}
	&.btn-dark {
		color:$dark;
		g [fill]{
			fill:$dark;
		}
		&:hover{
			background-color: $dark-light;
			border-color: $dark-light;
			color: $dark;
			g [fill]{
				fill:$white;
			}
		}
	}
}


.shadow.btn-primary {
    -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
    box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
}
.shadow.btn-secondary {
    -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba($secondary,0.2) !important;
    box-shadow: 0 0.3125rem 0.9375rem 0 rgba($secondary,0.2) !important;
}
.shadow.btn-warning {
    -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba($warning,0.2) !important;
    box-shadow: 0 0.3125rem 0.9375rem 0 rgba($warning,0.2) !important;
}
.shadow.btn-danger {
    -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba($danger,0.2) !important;
    box-shadow: 0 0.3125rem 0.9375rem 0 rgba($danger,0.2) !important;
}
.shadow.btn-info {
    -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba($info,0.2) !important;
    box-shadow: 0 0.3125rem 0.9375rem 0 rgba($info,0.2) !important;
}
.shadow.btn-success {
    -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba($success,0.2) !important;
    box-shadow: 0 0.3125rem 0.9375rem 0 rgba($success,0.2) !important;
}



.btn-xxs {
    padding: 0.375rem 0.9375rem;
    font-size: 0.6875rem;
    line-height: 1.3;
}
.btn-xs {
    font-size: 0.75rem;
    padding: 0.438rem 1rem;
	font-weight: 600;
}

.btn-sm {
    font-size: 0.813rem  !important;
	padding:0.625rem 1rem;
}
.btn-md {
    font-size: 0.875rem  !important;
	padding: 0.875rem 1.25rem;
}
.btn-lg{
    padding: 1rem 2rem;
    font-size: 1.125rem !important;
	@include respond('phone') {
		 padding: 0.75rem 1.25rem;
	}
}

.btn-xl{
    padding: 0.6rem 1rem;

    &.btn-default{
        font-weight: 600;
    }
}

.btn-square {
    border-radius: 0;
}

.btn-rounded {
    border-radius: 2.5rem !important;
}

.btn-icon-end {
	border-left: 0.0625rem solid var(--border);
    display: inline-block;
    margin: -.8rem 0 -.8rem 1rem;
    padding: 0.4375rem 0 0.4375rem 1rem;
    margin: -1rem -0.25rem -1rem 1rem;
    padding: 0.7rem 0 0.7rem 1.25rem;
}

.btn-icon-start {
    background: #fff;
    border-radius:$radius;
    display: inline-block;
	margin: -0.2rem 0.55rem -0.4rem -1.188rem;
    padding: 0.3rem 0.6rem 0.2rem;
    float: left;
	@include respond('laptop') {
		margin: -0.5rem 0.75rem -0.5rem -0.880rem;
	}
}

.toggle-dropdown::after {
    margin-left: 0.755em;
}

.social-btn-icon {
    .btn {
        min-width    : 7.5rem;
        margin-bottom: 1.5rem;
    }
}

.social-icon {
    .btn {
        padding: .7rem 1.4rem;
    }
}

.btn-circle {
    height       : 5rem;
    width        : 5rem;
    border-radius: 50% !important;

    &-sm {
        width: 4.5rem;
        height: 4.5rem;
        font-size: 1.8rem;
    }

    &-md {
        width: 6rem;
        height: 6rem;
        font-size: 2.5rem;

        i{
            font-size: 2.4rem;
        }
    }

    &-lg {
        width: 8rem;
        height: 8rem;
        font-size: 3.2rem;

        i{
            font-size: 3.1rem;
        }
    }
}

.btn-page{
    .btn{
        min-width: 6.875rem;
        margin-#{$dlab-pos-right}: 0.25rem;
        margin-bottom: 0.5rem;

    }

}



// btn sizes
.size-1{
    min-width: 10rem !important;
    font-size: 1.5rem;
    padding: 0.68rem 0.75rem;
}

.size-2{
    font-size: 1.25rem;
    min-width: 8.125rem !important;
    padding: 0.57rem 0.75rem;
}

.size-3{
    font-size: 0.875rem;
    min-width: 6.875rem !important;
    padding: 0.536rem 0.75rem;
}
.size-4{
    font-size: 0.875rem;
    min-width: 6.25rem !important;
}
.size-5{
    font-size: 0.875rem;
    min-width: 5.625rem !important;
    padding: .22rem 0.75rem;
}
.size-6{
    font-size: 0.8125rem;
    min-width: 5rem !important;
    padding: 0.097rem 0.75rem;
}
.size-7{
    font-size: 0.75rem;
    min-width: 3.75rem !important;
    padding: 0.001rem 0.75rem;
}





.btn-light {
    background  : $light;
    border-color: $light;
    color       : $dark;
    
    &:active,
    &:focus,
    &:hover {
        background  : #d7d7db;
		border-color:transparent;
    }
}

.btn-outline-primary{
	&:hover{
		color:$white;
	}
}
.btn-outline-secondary{
	&:hover{
		color:$white;
	}
}
.btn-outline-success{
	&:hover{
		color:$white;
	}
}
.btn-outline-info{
	&:hover{
		color:$white;
	}
}
.btn-outline-warning{
	&:hover{
		color:$white;
	}
}
.btn-outline-danger{
	&:hover{
		color: $white;
	}
}
.btn-outline-light{
    color: $dark;
	
}



.btn-dark {
    background  : $dark;
    border-color: $dark;
    color       : $white;
    
    
    &:active,
    &:focus,
    &:hover {
        background  : darken($dark, 10%);
        color       : $white;
        border-color: darken($dark, 10%);
    }
}

.btn-group{

    &.btn-rounded{

        .btn:first-child{
            border-top-left-radius: 1.875rem;
            border-bottom-left-radius: 1.875rem;
        }

        .btn:last-child{
            border-top-#{$dlab-pos-right}-radius: 1.875rem;
            border-bottom-#{$dlab-pos-right}-radius: 1.875rem;
        }
    }
}



///////////////////
// Social Buttons
///////////////////

///////////////////
// Social Buttons
///////////////////

.btn-facebook {
	background: $facebook;
	border-color: $facebook;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($facebook, 10%)!important;
		color: $white;
		border-color: darken($facebook, 10%);
	}
}

.btn-twitter {
	background: $twitter;
	border-color: $twitter;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($twitter, 10%)!important;
		color: $white;
		border-color: darken($twitter, 10%);
	}
}

.btn-youtube {
	background: $youtube;
	border-color: $youtube;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($youtube, 10%)!important;
		color: $white;
		border-color: darken($youtube, 10%);
	}
}

.btn-instagram {
	background: $instagram;
	border-color: $instagram;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($instagram, 10%)!important;
		color: $white;
		border-color: darken($instagram, 10%);
	}
}

.btn-pinterest {
	background: $pinterest;
	border-color: $pinterest;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($pinterest, 10%)!important;
		color: $white;
		border-color: darken($pinterest, 10%);
	}
}

.btn-linkedin {
	background: $linkedin;
	border-color: $linkedin;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($linkedin, 10%)!important;
		color: $white;
		border-color: darken($linkedin, 10%);
	}
}

.btn-google-plus {
	background: $google-plus;
	border-color: $google-plus;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($google-plus, 10%)!important;
		color: $white;
		border-color: darken($google-plus, 10%);
	}
}

.btn-google {
	background: $google;
	border-color: $google;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($google, 10%)!important;
		color: $white;
		border-color: darken($google, 10%);
	}
}

.btn-snapchat {
	background: $snapchat;
	border-color: $snapchat;
	color: $black;

	&:active,
	&:focus,
	&:hover {
		background: darken($snapchat, 10%)!important;
		color: $black;
		border-color: darken($snapchat, 10%);
	}
}

.btn-whatsapp {
	background: $whatsapp;
	border-color: $whatsapp;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($whatsapp, 10%)!important;
		color: $white;
		border-color: darken($whatsapp, 10%);
	}
}

.btn-tumblr {
	background: $tumblr;
	border-color: $tumblr;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($tumblr, 10%)!important;
		color: $white;
		border-color: darken($tumblr, 10%);
	}
}

.btn-reddit {
	background: $reddit;
	border-color: $reddit;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($reddit, 10%)!important;
		color: $white;
		border-color: darken($reddit, 10%);
	}
}

.btn-spotify {
	background: $spotify;
	border-color: $spotify;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($spotify, 10%)!important;
		color: $white;
		border-color: darken($spotify, 10%);
	}
}

.btn-yahoo {
	background: $yahoo;
	border-color: $yahoo;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($yahoo, 10%)!important;
		color: $white;
		border-color: darken($yahoo, 10%);
	}
}

.btn-dribbble {
	background: $dribbble;
	border-color: $dribbble;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($dribbble, 10%)!important;
		color: $white;
		border-color: darken($dribbble, 10%);
	}
}

.btn-skype {
	background: $skype;
	border-color: $skype;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($skype, 10%)!important;
		color: $white;
		border-color: darken($skype, 10%);
	}
}

.btn-quora {
	background: $quora;
	border-color: $quora;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($quora, 10%)!important;
		color: $white;
		border-color: darken($quora, 10%);
	}
}

.btn-vimeo {
	background: $vimeo;
	border-color: $vimeo;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: darken($vimeo, 10%)!important;
		color: $white;
		border-color: darken($vimeo, 10%);
	}
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show{
	background-color:var(--primary);
	border-color:var(--primary);
	color:$white;
}
 
.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus{
	box-shadow:0 0 0 0.25rem var(--rgba-primary-5);
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus{
	box-shadow:0 0 0 0.25rem var(--rgba-primary-5);
}
.btn-close:focus{
	box-shadow:0 0 0 0.25rem var(--rgba-primary-5);
}
.btn-icon-lg {
    padding: 15px 18px;
    min-width: 20px !important;
}
.btn-icon-md {
    padding: 13px 16px;
    min-width: 20px !important;
}
.btn-icon-sm {
    padding: 11px 14px;
    min-width: 20px !important;
}
.btn-icon-xs {
    padding: 10px 12px;
    min-width: 20px !important;
}
.btn-icon-xxs {
    padding: 8px 10px;
    min-width: 20px !important;
}

.btn-close{
	background:none;
	position:relative;
	font-family: "Font Awesome 5 Free";
	margin-#{$dlab-pos-left}: auto !important;
	margin-#{$dlab-pos-right}: unset !important;
	color:var(--text-dark);
	&:before{
		content:'\f00d';
		font-weight: 600;
		
	}
	&:hover{
		color:var(--text-dark);
	}
}

// override bootstrap buttons
.btn-group{
	& > .btn:not(:last-child):not(:first-child):not(.dropdown-toggle){
		border-radius: 0 ;
	}
	& > .btn:not(:last-child):not(.dropdown-toggle){
		border-top-#{$dlab-pos-right}-radius: 0;
		border-bottom-#{$dlab-pos-right}-radius: 0;
		border-top-#{$dlab-pos-left}-radius: $radius;
		border-bottom-#{$dlab-pos-left}-radius: $radius;
	}
	& > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn{
		border-top-#{$dlab-pos-right}-radius: 0;
		border-bottom-#{$dlab-pos-right}-radius: 0;
		border-top-#{$dlab-pos-left}-radius: $radius;
		border-bottom-#{$dlab-pos-left}-radius: $radius;
	}
	button.dropdown-toggle.dropdown-toggle-split:first-child{
		border-top-#{$dlab-pos-right}-radius: 0 !important;
    	border-bottom-#{$dlab-pos-right}-radius: 0 !important;
		border-top-#{$dlab-pos-left}-radius: $radius !important;
		border-bottom-#{$dlab-pos-left}-radius: $radius !important;
	}
	button.dropdown-toggle.dropdown-toggle-split{
		border-top-#{$dlab-pos-left}-radius: 0 !important;
    	border-bottom-#{$dlab-pos-left}-radius: 0 !important;
		border-top-#{$dlab-pos-right}-radius: $radius !important;
		border-bottom-#{$dlab-pos-right}-radius: $radius !important;
	}
	& > :not(.btn-check) + .btn{
		border-top-#{$dlab-pos-left}-radius: 0;
    	border-bottom-#{$dlab-pos-left}-radius: 0;
		border-top-#{$dlab-pos-right}-radius: $radius;
		border-bottom-#{$dlab-pos-right}-radius: $radius;
	}
	& > .btn-group:not(:first-child) > .btn{
		border-top-#{$dlab-pos-left}-radius: 0;
    	border-bottom-#{$dlab-pos-left}-radius: 0;
		border-top-#{$dlab-pos-right}-radius: $radius;
		border-bottom-#{$dlab-pos-right}-radius: $radius;
	}
}

.dropdown-toggle::after{
	margin: 0;
	margin-#{$dlab-pos-left}: 0.255em;
}
.btn-close{
	&:focus{
		box-shadow:unset;
	}
}
