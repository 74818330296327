.list-group-item {
    background-color: rgba(255,255,255,0);
    border: 1px solid var(--border);
	padding: 1rem 1.5rem;
	&.active{
		background-color :var(--primary)!important;
		border-color :var(--primary);
		color: $white;
		&:focus{
			color:$white;
		}
	}
}

.list-group-item.disabled, .list-group-item:disabled {
    color: $white;
    background-color: $secondary;
    border-color: $secondary;
}
[class*="bg-"]{
	.list-group-item{
		border-color:rgba($white, 0.05);
		color: $white;
	}
}
.bg-warning{
	.list-group-item{
		border-color:rgba($black, 0.05);
	}
}
.list-group-item-action:active{
	background-color: var(--card);
}
.list-group-item-action:focus{
	color:var(--text-dark);
}
