
[data-sidebar-style="full"][data-layout="vertical"] {
    .dlabnav{
		
		.metismenu{

			& > li{
				padding:0 1rem;
				& > a{
					font-size: 0.938rem;
					padding: 0.7375rem 1rem;
					font-weight:400;
					border-radius:$radius;
					@include transitionMedium;
					margin: 5px 0;
					
				}
				&.mm-active{
					& > a{
						font-weight:500;
						background:var(--primary);
						color:white;
						 &:before{
							width:0.375rem;
							@include respond ('laptop'){
								width:0.600rem;	
							}
						}
					
						i{
							color:white;
							font-weight: 500;
						}
					}
				}
				.has-arrow:after{
					#{$dlab-pos-right}: 1.5rem;
				}
				@include respond ('laptop') {
					padding:0 10px;	
					& > a{
						font-size: 1rem;
					}
				}
			}
			
		}
		
	}
	.menu-toggle {
		
        .nav-header {
            width: 5.5rem;
            z-index: 999;
            .brand-logo {
                padding-#{$dlab-pos-left}: 0;
                padding-#{$dlab-pos-right}: 0;
                justify-content: center;
            }
            .nav-control {
				#{$dlab-pos-right}: -5.3rem;
                .hamburger {
                    .line {
                        background-color: $white;
                    }
                }
            }
        }
		
        .header {
            padding-#{$dlab-pos-left}: 5.5rem;
            width: 100%;
            
			.header-content{
				width:calc(100% - 5.5rem);
				#{$dlab-pos-left}:5.5rem;
			}
			.page-titles{
				padding-#{$dlab-pos-left}: 2.6rem;
			}
        }
        .dlabnav {
            width: 5.5rem;
            overflow: visible;
            position: absolute;
            .nav-text,
			.badge {
                display: none;
            }
            .slimScrollDiv,
            .dlabnav-scroll {
                overflow: visible !important;
            }
			.header-info2{
				padding:0;	
				img{
					margin-#{$dlab-pos-left}:-1rem;	
				}
			}
			.sidebar-info{
				display:none!important;	
			}
			.header-profile2{
				margin: 0 0.5rem;	
			}
            .metismenu {
			
				
                li {
                    position: relative;
					
                    a {
						background:transparent;
						margin: 0.125rem 0;
						
						svg{
						    max-width: 1.5rem;
							max-height: 1.5rem;
							margin-#{$dlab-pos-right}: 0;
						}
						&:before{
							content:none;
						}
						i{
							margin: 0;
						}
                    }
                    &>ul {
                        position: absolute;
						#{$dlab-pos-left}: 5.4rem;
						top: 0;
						width: 12rem;
						z-index: 1001;
						display: none;
						padding-#{$dlab-pos-left}: 0.0625rem;
						height: auto !important;
						box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
						border-radius: 0.375rem;
						margin-#{$dlab-pos-left}: 0;
						border:0;
						background:$white;

                       
                        li:hover {
                            ul {
                                
                                #{$dlab-pos-left}: 11.8125rem;
                                top: 0;
								&:after{
									content:none;
								}
                            }
                        }
                    }
                    &:hover>ul {
                        display: block;
                        height: auto;
                        overflow: visible;
                    }
                }
                &>li {
                    transition: all 0.4s ease-in-out;
					padding: 0 1.125rem;
					
                    &>a {
						padding: 0.75rem 0.925rem;
						text-align:center;
						border-radius:0.5rem;
                        &.has-arrow {
                            &:after {
                                display: none;
                            }
                        }
                    }
                    &.mm-active > a{
						background:var(--primary);
						border-radius:0.5rem;
						i{
							color:$white;
							padding:0;
						}
					}
                    &:hover{
                        &:nth-last-child(-n + 1) {
                            &>ul {
                                bottom: 0;
                                top: auto;
                            }
                        }
                        &>a {
							border-radius: $radius;
							background:var(--primary);
							color:$white;
							@at-root [data-theme-version="dark"]#{&} {
								background:var(--primary);
							}
							i{
								color:$white;
							}
                        }
                        &>ul {
                            height: auto !important;
							padding: 0.625rem 0;	
							background: var(--card);					
							
                            a {
                                padding: 0.375rem 1.25rem 0.375rem 1.25rem;
                                margin-#{$dlab-pos-left}: -.1rem;
                            }
                            ul{
								padding: 0.625rem 0;
								a {
									padding: 0.375rem 1.25rem 0.375rem 1.25rem;
									margin-#{$dlab-pos-left}: -.1rem;
									
								}
                            }
                        }
                    }
                }
                .nav-label,
                .nav-badge {
                    display: none;
					
                }
            }
			.feature-box{
				display:none;
			}
			.support-box{
				display:none;
			}
			.copyright,
			.plus-box,
			.main-menu{
				display:none;
			}
        }
        .content-body {
            margin-#{$dlab-pos-left}: 5.5rem;
           
        }
        &+.footer {
            padding-#{$dlab-pos-left}: 5.7rem;
            
        }
		
    }
}
[data-sidebar-style="full"][data-layout="horizontal"] {
	.header .header-content{
		padding-#{$dlab-pos-left}: 1.875rem;
	}
}
