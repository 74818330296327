//style for all basic tables
.table {
	--bs-table-bg:var(--card);
	strong{
		color:var(--text-dark);
	}
    th, td {
        border-color: var(--border);
		padding: 0.9375rem 0.625rem;
		
       
    }
	&.table-striped tbody tr:nth-of-type(odd),
	&.table-hover tr:hover{
	    background-color:var(--body-bg);
		
		
	}

	&.shadow-hover tbody tr{
		&:hover{
			background-color:var(--card);
			box-shadow: 0 0rem 1.875rem var(--rgba-primary-2);
		}
		td{
			background-color: transparent;
		}
	

	}
	&.tr-rounded{
		tr{
			td,th{
				&:first-child{
					border-top-#{$dlab-pos-left}-radius:$radius;
					border-bottom-#{$dlab-pos-left}-radius: $radius;
				}
				&:last-child{
					border-top-#{$dlab-pos-right}-radius:$radius;
					border-bottom-#{$dlab-pos-right}-radius: $radius;
				}
			}
		}
	}
	&.order-tbl{
		tr{
			th{
				font-size: 14px;
				font-weight: 600;
				border:0;
			}
			td{
				font-weight:500;
				border:0;
			}
		}
	}
	&.border-hover{
		tr{
			td{
				border-width:0.0625rem 0 0.0625rem 0;
				border-bottom:0.0625rem solid transparent;
				border-color:transparent;
				&:first-child{
					border-width:0.0625rem 0 0.0625rem 0.0625rem;
				}
				&:last-child{
					border-width:0.0625rem 0.0625rem 0.0625rem 0;
				}
			}
			&:hover{
				td{
					border-color:var(--border);
				}
			}
		} 
	}
	&.bg-secondary-hover{
		td{
			color:$black;
			padding-#{$dlab-pos-left}:0;
		}
		th{
			color:$secondary!important;
			
		}
		tr:hover{
			td,th{
				background:$secondary;
				color:$white;
				box-shadow:10px 0 30px rgba(65,63,187,0.3);
				
			}
		}	
	}	
	&.bg-primary-hover{
		td{
			color:var(--text-dark);
			padding-#{$dlab-pos-left}:0;
		}
		th{
			color:var(--primary);
		}
		tr:hover{
			td,th{
				background:var(--primary);
				color:$white;
				box-shadow:10px 0 30px rgba(54,147,255,0.3);
			}
		}	
	}	
	&.bg-eth-hover{
		td{
			color:$black;
			padding-#{$dlab-pos-left}:0;
		}
		th{
			color:#00ADA3;
		}
		tr:hover{
			td,th{
				background:#00ADA3;
				color:$white;
				box-shadow:10px 0 30px rgba(0,173,163,0.3);
			}
		}	
	}	
	&.bg-ripple-hover{
		td{
			color:$black;
			padding-#{$dlab-pos-left}:0;
		}
		th{
			color:#23292F;
		}
		tr:hover{
			td,th{
				background:#23292F;
				color:$white;
				box-shadow:10px 0 30px rgba(35,41,47,0.3);
			}
		}	
	}	
	&.bg-pink-hover{
		th,td{
		}
		td{
			color:var(--text-dark);
			padding-#{$dlab-pos-left}:0;
		}
		th{
			color:$pink;
		}
		tr:hover{
			td,th{
				background:$pink;
				color:$white;
				box-shadow:10px 0 30px rgba(172,76,188,0.3);
			}
		}	
	}	
	&.bg-secondary-hover{
		td{
			color:#5b5e81;
		}
		th{
			color:#6C6C6C;
		}
		tr:hover{
			td,th{
				background:var(--secondary);
				color:$white!important;
			}
		}	
	}	
	&.bg-info-hover{
		td{
			color:$black;
		}
		th{
			color:#6C6C6C;
		}
		tr:hover{
			td,th{
				background:$info;
				color:$white!important;
			}
		}	
	}	
	&.bg-warning-hover{
		td{
			color:var(--text);
		}
		th{
			color:var(--text-dark);
		}
		tr:hover{
			td,th{
				background:$warning;
				color:$white!important;
			}
		}	
	}	
	&.border-no{
		td{
			border:0;
		}
	}
	&.short-one{
		tr{
			td,th{
				&:first-child{
					width:3.75rem
				}
			}
		}
	}

	thead th {
        border-bottom: 0.0625rem solid var(--border);
        text-transform: capitalize;
        font-size: 1rem;
		white-space:nowrap;
        font-weight: 500;
		color:var(--text-dark);
        border-color: var(--border)!important;
        
    }
    tbody  tr td {
        vertical-align: middle;
        border-color: var(--border);
       
    }
	
	//delete header borders without '.borders tables'
    &:not(.table-bordered) {
        thead th {
            border-top: none;
        }
    }

    //heading background
    .thead-primary th {
        background-color: var(--primary);
        color: $white;
    }
	.thead-info th {
        background-color: $info;
        color: $white;
    }
	.thead-warning th {
        background-color: $warning;
        color: $white;
    }
	.thead-danger th {
        background-color: $danger;
        color: $white;
    }
	.thead-success th {
        background-color: $success;
        color: $white;
    }

    //table border
    &.primary-table-bordered {
        border: 0.0625rem solid var(--border);
		
    }

    //table hover
	&.primary-table-bg-hover {
		tbody{
			border-color:var(--primary-dark);
		}
        thead th {
            background-color:var(--primary-dark);
            color: $white;
            border-bottom: none;
			border-color:var(--primary-dark);
        }

        tbody tr {
            background-color: var(--primary);
            color: $white;
			
            transition: all .1s ease;
			th,td{
				border-color:var(--primary-dark)!important;
				background-color: transparent;
				color:$white;
			}

            &:hover {
                background-color: var(--rgba-primary-8);
            }

            &:not(:last-child) {
                td, th {
                    border-bottom: 0.0625rem solid var(--primary-dark);
					border-color: $white;
					
					
                }
            }
			
        }
	}

    //responsive table width
    &-responsive {
        &-tiny {
            // @include media-breakpoint-down(xs) {
                min-width: 18.75rem;
            // }
        }

        &-sm {
            // @include media-breakpoint-down(sm) {
                min-width: 30rem !important;
            // }
        }

        &-md {
            // @include media-breakpoint-down(sm) {
                min-width: 36rem !important;
            // }
        }

        &-lg {
            min-width: 60.9375rem !important;
        }

        &-xl {
            min-width: 70.9375rem !important;
        }
    }
}
.table > :not(caption) > * > *{
	color: inherit;
}
.table-primary, 
.table-primary > th, 
.table-primary > td {
	background-color: var(--rgba-primary-1);
	color: var(--primary);
	
}
.table-success, 
.table-success > th, 
.table-success > td {
	background-color: rgba($success,0.2);
	color: $success;
	
}
.table-info, 
.table-info > th, 
.table-info > td {
	background-color: rgba($info,0.2);
	color: $info;
	
}
.table-warning, 
.table-warning > th, 
.table-warning > td {
	background-color: rgba($warning,0.2);
	color: $warning;
	
}
.table-danger, 
.table-danger > th, 
.table-danger > td {
	background-color: rgba($danger,0.2);
	color: $danger;
	
}
.table-active, 
.table-active > th, 
.table-active > td {
    background-color: rgba($light,0.2);
	
}


.table tbody tr td:last-child{
    text-align: #{$dlab-pos-right};
}
.table thead tr th:last-child {
    text-align: #{$dlab-pos-right} !important;
}




.card-table{
	th, td{
        &:first-child{
			padding-#{$dlab-pos-left}: 1.875rem;
			@include respond('phone') {
				padding-#{$dlab-pos-left}: 0.9375rem;
			}
		}
		&:last-child{
			padding-#{$dlab-pos-right}:1.875rem;
			@include respond('phone') {
				padding-#{$dlab-pos-right}: 0.9375rem;
			}
		}
    }
}
.table > :not(:first-child){
	border-top: 1px solid currentColor;
}

