.tooltip{

    &-wrapper{

        button:not(:last-child){
            margin-#{$dlab-pos-right}: 0.5rem;
        }

        button:hover{
            background: var(--primary);
            color: $white;
        }

        button{
            margin-bottom: .5rem;

            &:focus{
                box-shadow: none;
            }
        }
    }

    &-inner{
        border-radius: 0;
        background: #333333;
        font-size: 0.75rem;
        font-weight: 300;
        padding: 0.35rem 0.7rem;
    }

    
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before{
    // border-width: 0 0.6rem 0.6rem;
    border-bottom-color: #333333;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before{
    border-#{$dlab-pos-right}-color: #333333;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before{
    border-#{$dlab-pos-left}-color: #333333;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before{
    border-top-color: #333333;
}


// tooltip

.tooltip-inner {
	background-color:var(--rgba-primary-1);
	box-shadow: rgba(149, 157, 165, 0.2) 0rem .5rem 1.5rem;
	color: var(--primary);
	font-weight:400;
	width:auto;
	border-radius:$radius;
	&:before{
	  background-color:$white!important;
	}
	  
  }
  .tooltip .arrow{
	  border-top-color: #008ec3!important;
	  background-color:$white!important;
  }
  
	.tooltip.bs-tooltip-top .tooltip-arrow::before {
	  border-top-color:var(--primary); /* Set arrow color to red */
	  box-shadow: rgba(149, 157, 165, 0.2) 0rem .5rem 1.5rem;
	}
	
	.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
	  border-bottom-color: var(--primary); /* Set arrow color to red */
	  box-shadow: rgba(149, 157, 165, 0.2) 0rem .5rem 1.5rem;
	}
	
	.tooltip.bs-tooltip-start .tooltip-arrow::before {
	  border-#{$dlab-pos-left}-color:var(--primary); /* Set arrow color to red */
	  box-shadow: rgba(149, 157, 165, 0.2) 0rem .5rem 1.5rem;
	}
	
	.tooltip.bs-tooltip-end .tooltip-arrow::before {
	  border-#{$dlab-pos-right}-color:var(--primary); /* Set arrow color to red */
	  box-shadow: rgba(149, 157, 165, 0.2) 0rem .5rem 1.5rem;
	}